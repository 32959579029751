const brandArticleAssets = {
  'add-services': '9945195',
  'post-production': '9945466',
  'pre-production': '9945464',
}

export type BrandArticleId = keyof typeof brandArticleAssets

export const getBrandArticle = (id: BrandArticleId) => brandArticleAssets[id]

const makerArticleAssets = {
  'post-production': '10046093',
  'pre-production': '10046046',
}

export type MakerArticleId = keyof typeof makerArticleAssets

export const getMakerArticle = (id: MakerArticleId) => makerArticleAssets[id]
